import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { APP_TITLE } from '@core/constants';

import { AuthGuard } from '@core/guards/auth.guard';
import { NoAuthGuard } from '@core/guards/no-auth.guard';

import { LayoutComponent } from '@shared/layout/layout.component';
import { FullLayoutComponent } from '@shared/full-layout/full-layout.component';

// import { ConfirmComponent } from '@modules/auth/pages/confirm/confirm.component';
import { DeactivatedComponent } from '@modules/auth/pages/deactivated/deactivated.component';
import { MaintenanceComponent } from '@modules/home/pages/maintenance/maintenance.component';
import { DisclosuresComponent } from '@modules/home/pages/disclosures/disclosures.component';
import { LogoutComponent } from '@modules/auth/pages/logout/logout.component';

const routes: Routes = [
  {
    path: '',
    component: FullLayoutComponent,
    loadChildren: () =>
      import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'properties',
    component: LayoutComponent,
    loadChildren: () =>
      import('./modules/properties/properties.module').then(
        (m) => m.PropertiesModule
      ),
  },
  {
    path: 'maintenance',
    component: MaintenanceComponent,
    title: APP_TITLE("We're preparing to serve you better"),
  },
  // {
  //   path: 'auth/confirm',
  //   component: ConfirmComponent,
  //   title: APP_TITLE('Verify your identity'),
  // },
  {
    path: 'auth/deactivated',
    component: DeactivatedComponent,
    title: APP_TITLE('Account deactivated'),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [NoAuthGuard],
  },
  {
    path: 'logout',
    component: LogoutComponent,
  },
  {
    path: 'disclosures',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: DisclosuresComponent,
        title: APP_TITLE('Disclosures'),
      },
    ],
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: '',
        component: LayoutComponent,
        loadChildren: () =>
          import('./modules/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
      },
    ],
  },
  {
    path: 'buy-boxes',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: LayoutComponent,
        loadChildren: () =>
          import('./modules/buy-boxes/buy-boxes.module').then(
            (m) => m.BuyBoxesModule
          ),
      },
    ],
  },
  {
    path: 'ui-docs',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
      },
      {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        loadChildren: () =>
          import('./modules/ui-docs/ui-docs.module').then(
            (m) => m.UIDocsModule
          ),
      },
    ],
  },
  {
    path: 'sell',
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/sell/sell.module').then((m) => m.SellModule),
      },
    ],
  },
  {
    path: 'opt-out',
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/opt-out/opt-out.module').then(
            (m) => m.OptOutModule
          ),
      },
    ],
  },
  // Fallback when no prior routes is matched
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
